<template>
  <div>
    <!-- recherche -->
    <div class="position-relative">
      <b-card no-body class="invoice-preview-card">
        <b-card class="m-0 mb-1">

          <b-row>
            <!-- <b-col lg="4">
              <div class="d-flex flex-column col-6 nopadding">
                ok
              </div>
            </b-col> -->
            <b-col lg="12">
              <!-- {{ tableData[0].reference }} -->

              <!-- recherche par periode -->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group class="col pr-0 pl-0" label-cols-xl="3" label="Rechercher par période d'enregistrement">
                  <b-input-group>
                    <DateRangeWidget v-model="filterBy.periode" :range="filterBy.periode"
                      placeholder="Filtrer par date" />
                  </b-input-group>
                </b-form-group>
              </div>

              <!-- nom etablissement  -->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group class="col pr-0 pl-0" label-cols-xl="3" input-cols-xl="9"
                  label="Rechercher par Établissement associé">
                  <b-input-group>
                    <v-select v-model="filterBy.etablissement" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="nomEtablissement" :reduce="option => option._id" :options="tableDataEtablissement"
                      placeholder="Choisissez un établissement. Exemple: Home Bénin" />
                  </b-input-group>
                </b-form-group>
              </div>
              <!-- role  -->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group class="col pr-0 pl-0" label-cols-xl="3" label="Rechercher par rôle">
                  <b-input-group class="w-100">
                    <v-select v-model="filterBy.role" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nom"
                      :reduce="option => option._id" :options="tableDataRole"
                      placeholder="Choisissez un rôle. Exemple: Agent MTCA" />
                  </b-input-group>
                </b-form-group>
              </div>

              <!-- nom-->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group class="col pr-0 pl-0" label-cols-xl="3" label="Rechercher par le nom">
                  <b-input-group>
                    <b-form-input v-model="filterBy.lastname" type="text" class="form-control"
                      placeholder="Entrer l'email de l'utilisateur. Exemple: ATTOU" />
                    <b-input-group-append is-text @click="() => {
                        filterBy.lastname = null
                      }
                      ">
                      <feather-icon v-b-tooltip.hover.bottom="'Vider le champs!'" icon="XCircleIcon"
                        class="cursor-pointer" />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>
              <!-- prenom -->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group class="col pr-0 pl-0" label-cols-xl="3" label="Rechercher par le prénom">
                  <b-input-group>
                    <b-form-input v-model="filterBy.firstname" type="text" class="form-control"
                      placeholder="Entrer le prénom de l'utilisateur. Exemple: Luc" />
                    <b-input-group-append is-text @click="() => {
                        filterBy.firstname = null
                      }
                      ">
                      <feather-icon v-b-tooltip.hover.bottom="'Vider le champs!'" icon="XCircleIcon"
                        class="cursor-pointer" />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>
              <!-- email -->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group class="col pr-0 pl-0" label-cols-xl="3" label="Rechercher par l'email">
                  <b-input-group>
                    <b-form-input v-model="filterBy.email" type="text" class="form-control"
                      placeholder="Entrer l'email de l'utilisateur. Exemple: agent@gmail.com" />
                    <b-input-group-append is-text @click="() => {
                        filterBy.email = null
                      }
                      ">
                      <feather-icon v-b-tooltip.hover.bottom="'Vider le champs!'" icon="XCircleIcon"
                        class="cursor-pointer" />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>

              <!-- button -->
              <div class="d-flex flex-row-reverse nopadding">
                <b-button class="float-right" variant="primary" @click="search()">
                  <feather-icon icon="SearchIcon" class="mr-25" />
                  <span>Rechercher</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

          <hr class="my-2">

        </b-card>
      </b-card>
    </div>

    <!-- table -->
    <b-table responsive :items="tableData" :fields="tableColumns" show-empty empty-text="Aucun resultats"
      :busy="isLoadingTableData">
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <template #cell(id)="data">
        <strong>{{
          (paginationData.perPage * (paginationData.currentPage - 1) || 0) +
          data.index +
          1
        }}</strong>
      </template>
      <template #cell(ref)="data">
        <b-avatar variant="dark">
          <feather-icon icon="ImageIcon" /> </b-avatar>{{ " " }}
        {{ data.item.ref }}
      </template>

      <template #cell(forms)="data">
        {{ (data && data.item.forms && data.item.forms.length) || 0 }}
      </template>
      <template #cell(actions)="data">
        <div class="d-flex flex-row">
          <b-button v-if="true" variant="primary" :to="{
            name: 'users-details',
            params: { userId: data.item._id },
          }">
            <feather-icon icon="EyeIcon" />
            <span class="text-nowrap"> Voir</span>
          </b-button>

        </div>

      </template>
    </b-table>

    <div>
      <PaginationComponent :table-data="tableData" :per-page="paginationData.perPage" :total-rows="paginationData.total"
        @change="onPaginationChange" />
    </div>
  </div>
</template>

<script>
import {
  BPagination,
  BAvatar,
  BButton,
  BTable,
  BSpinner,
  BFormSelect,
  BCardText,
  BCard,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  VBTooltip,
  BDropdownDivider,
  BCardTitle,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ButtonArchiverUtilisateur from '@/views/apps/utilisateurs/administration/component/ButtonArchiverUtilisateur.vue'
import DateRangeWidget from '@/components/DateRangeWidget.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import PaginationComponent from '@/components/PaginationComponent.vue'

// store modules and vuex utilities
import etablissementsStroreModule from '@/store/etablissements'
import paramsStoreModule from '@/store/params'
import rolesStoreModule from '@/store/roles'
import usersStoreModule from '@/store/users'
import agentGouvenementStoreModule from '@/store/agent-gouvenement'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  // name: 'UsersListComponent',
  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStroreModule },
      { path: 'params', module: paramsStoreModule },
      { path: 'users', module: usersStoreModule },
      { path: 'roles', module: rolesStoreModule },
      { path: 'agentGouvenement', module: agentGouvenementStoreModule },

    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },

  components: {
    BFormSelect,
    BAvatar,
    BPagination,
    BButton,
    BTable,
    BSpinner,
    BCardText,
    BCard,
    BCardBody,
    BCardHeader,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    VBTooltip,
    BDropdownDivider,
    BCardTitle,
    vSelect,

    ButtonArchiverUtilisateur,
    DateRangeWidget,
    PaginationComponent,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  props: { etablissementId: null },

  data() {
    return {
      form: {
        role: '',
      },

      confirmeArchiveAgentModalRef: 'confirmeArchiveAgentModal',

      filters: { options: {} },
      currentPage: 1,
      perPage: 10,
      rows: 100,
      tableData: null,
      tableDataEtablissement: [],
      tableDataRole: [],
      filteredTableData: [],
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 20,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200],
      tableColumns: [
        {
          key: 'id', label: '#', sortable: true, stickyColumn: true,
        },
        {
          key: 'lastname', label: 'Nom', sortable: true, stickyColumn: true,
        },
        {
          key: 'firstname', label: 'Prénom', sortable: true, stickyColumn: true,
        },
        {
          key: 'email', label: 'Email', sortable: true, stickyColumn: true,
        },
        {
          key: 'role.nom', label: 'Rôle', sortable: true, stickyColumn: true,
        },

        'actions',
      ],
      isLoadingTableData: false,

      query: {
        options: {},
        filter: {},
      },
      filterBy: {
        periode: {},
        email: null,
        firstname: null,
        lastname: null,
        etablissement: null,
        role: null,
      },
    }
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },

  computed: {
    ...mapGetters('params', {
      typesRoles: 'getter_typesRoles',
    }),
    totalRows() {
      return (this.tableData && this.tableData.length) || 0
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.getListUsers()
        this.getListEtablissement()
        this.getListRole()
      },
    },
    perPage: {
      immediate: true,
      handler(val, old) {
        if (this.tableData && this.tableData[val - 1]) {
          this.filteredTableData = this.customCloneDeep(
            this.tableData.slice(0, val - 1),
          )
        }
      },
    },
    tableData: {
      immediate: true,
      handler(val, old) {
        if (this.tableData) {
          this.filteredTableData = this.customCloneDeep(this.tableData)
        }
      },
    },
    selectedCategorie: {
      immediate: true,
      async handler(val, old) {
        await this.action_fetchTypesRoles()
        this.getListUsers()
      },
    },
    'paginationData.currentPage': {
      handler(val, old) {
        this.getListUsers()
      },
    },
    'paginationData.perPage': {
      handler(val, old) {
        this.getListUsers()
      },
    },
  },
  methods: {
    ...mapActions('users', { action_fetchUsers: 'fetchUsers' }),
    ...mapActions('params', { action_fetchTypesRoles: 'fetchTypesRoles' }),
    ...mapActions('etablissements', { getAgent: 'getAgent', fetchEtablissements: 'fetchEtablissements' }),
    ...mapActions('roles', { fetchRoles: 'fetchRoles' }),
    ...mapActions('agentGouvenement', {
      createAgentGouvenement: 'createAgentGouvenement',
      findAgentGouvenement: 'findAgentGouvenement',
    }),
    // ----------------------------------------------------------------
    async getListUsers(somesParams) {
      this.isLoadingTableData = true
      const userRoleReq = await this.action_fetchTypesRoles().catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message || 'Une erreur est survenue',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      if (!userRoleReq || !userRoleReq.data) {
        this.isCheckingAgentCount = false
        this.canAddNewAgent = false
        return
      }
      const { resources } = userRoleReq.data
      const userRole = (resources || []).find(e => e.code === 'agent_mtca')

      const userRoleId = userRole._id

      if (
        somesParams
        && somesParams.filter
        && somesParams.filter.text
        && somesParams.filter.text.length === 0
        && somesParams.filter.type.length === 0
      ) {
        somesParams.filter = {
          // text: [],
          // type: [],
        }
        somesParams.options = {
          isArchive: false,
        }
      }

      const params = {
        ...somesParams,
        page: this.paginationData.currentPage - 1,
        perPage: this.paginationData.perPage,
      }
      params.options = {
        isArchive: false,
      }
      console.log('params::: ⭕️', params)
      this.action_fetchUsers(params)
        .then(async response => {
          this.tableData = response.data.resources || []
          this.paginationData.total = response.data.total || 0
          this.isLoadingTableData = false

          if (this.paginationData.metaData.from == 0) { this.paginationData.metaData.from += 1 } else {
            this.paginationData.metaData.from = this.paginationData.perPage * this.paginationData.currentPage
              - this.paginationData.perPage
          }
          this.paginationData.metaData.to = this.paginationData.perPage * this.paginationData.currentPage
            - this.paginationData.perPage
            + this.tableData.length
        })
        .catch(err => {
          this.isLoadingTableData = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    async getListEtablissement() {
      this.fetchEtablissements()
        .then(async response => {
          this.tableDataEtablissement = response.data.resources || []
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    async getListRole() {
      this.fetchRoles()
        .then(async response => {
          this.tableDataRole = response.data.resources || []
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    search() {
      if (
        !this.filterBy.periode
        && !this.filterBy.email
        && !this.filterBy.firstname
        && !this.filterBy.lastname
        && !this.filterBy.etablissement
        && !this.filterBy.role
      ) {
        this.query = {
          options: {},
          filter: {},
        },
          this.getListUsers({ ...this.query })
      }
      this.updateFilter()
      this.getListUsers({ ...this.query })
    },
    updateFilter() {
      this.query.filter = {
        text: [],
        type: [],
      }
      if (this.filterBy.periode) {
        const { from, to } = this.filterBy.periode
        if (from) {
          this.query.filter.type.push('createdAt')
          if (to) {
            this.query.filter.text.push(`${from},${to}`)
          } else {
            this.query.filter.text.push(`${from},${from}`)
          }
        }
      }
      if (this.filterBy.email && this.filterBy.email != '') {
        this.query.filter.type.push('email')
        this.query.filter.text.push(this.filterBy.email)
      }
      if (this.filterBy.firstname && this.filterBy.firstname != '') {
        this.query.filter.type.push('firstname')
        this.query.filter.text.push(this.filterBy.firstname)
      }
      if (this.filterBy.lastname && this.filterBy.lastname != '') {
        this.query.filter.type.push('lastname')
        this.query.filter.text.push(this.filterBy.lastname)
      }
      if (this.filterBy.etablissement && this.filterBy.etablissement != '') {
        this.query.filter.type.push('etablissement')
        this.query.filter.text.push(this.filterBy.etablissement)
      }
      if (this.filterBy.role && this.filterBy.role != '') {
        this.query.filter.type.push('role')
        this.query.filter.text.push(this.filterBy.role)
      }
    },
    onPaginationChange(val) {
      this.paginationData.currentPage = val.currentPage
      this.paginationData.perPage = val.perPage
    },
  },
  mounted() { },
}
</script>
<style lang="scss" scoped>
.link-wrapper {
  display: flex;
  height: 40px;
  align-items: center;
  gap: 10px;

  a {
    text-transform: uppercase;
  }
}

.footer,
.footer .per-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-select {
  width: 100%;
}
</style>
