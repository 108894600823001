<template>
  <div>
    <b-card class="">
      <!-- title -->
      <div class="d-flex flex-wrap justify-content-between">
        <h1 class="my-auto">
          {{ $t("user_manage.title") }}
        </h1>
        <div>
          <b-button v-b-modal.modal-add-user-administration variant="danger" class="mr-1">
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>{{ $t("user_manage.add_user_administration") }}</span>
          </b-button>
          <!-- <b-button v-b-modal.modal-add-admin variant="danger" class="">
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>{{ $t("user_manage.add_user") }}</span>
          </b-button> -->
        </div>
      </div>
      <hr />
      <!-- filter -->
      <app-collapse class="nopadding mt-2">
        <app-collapse-item :title="$t('user_manage.filter')">
          <!-- recherche -->
          <b-row>
            <b-col lg="6">
              <!-- recherche par periode -->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group class="col pr-0 pl-0" :label="$t('user_manage.filter_label_period')">
                  <b-input-group>
                    <DateRangeWidget v-model="filterBy.periode" :range="filterBy.periode"
                      placeholder="Filtrer par date" />
                  </b-input-group>
                </b-form-group>
              </div>
            </b-col>
            <b-col lg="6">
              <!-- nom etablissement  -->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group class="col pr-0 pl-0" input-cols-xl="9"
                  :label="$t('user_manage.filter_label_establishment_name')">
                  <b-input-group>
                    <v-select v-model="filterBy.etablissement" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="nom_etablissement" :reduce="(option) => option.nom_etablissement"
                      :options="tableDataEtablissement" placeholder="Choisissez un établissement. Exemple: Home Bénin" />
                  </b-input-group>
                </b-form-group>
              </div>
            </b-col>
            <b-col lg="6">
              <!-- role  -->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group class="col pr-0 pl-0" :label="$t('user_manage.filter_label_role')">
                  <b-input-group class="w-100">
                    <v-select v-model="filterBy.role" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                      :reduce="(option) => option.alias" :options="tableDataRole"
                      placeholder="Choisissez un rôle. Exemple: Agent MTCA" />
                  </b-input-group>
                </b-form-group>
              </div>
            </b-col>
            <b-col lg="6">
              <!-- nom-->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group class="col pr-0 pl-0" :label="$t('user_manage.filter_label_lastname')">
                  <b-input-group>
                    <b-form-input v-model="filterBy.lastname" type="text" class="form-control"
                      placeholder="Entrer l'email de l'utilisateur. Exemple: ATTOU" />
                    <b-input-group-append is-text @click="() => {
                      filterBy.lastname = null;
                    }
                      ">
                      <feather-icon v-b-tooltip.hover.bottom="'Vider le champs!'" icon="XCircleIcon"
                        class="cursor-pointer" />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>
            </b-col>
            <b-col lg="6">
              <!-- prenom -->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group class="col pr-0 pl-0" :label="$t('user_manage.filter_label_firstname')">
                  <b-input-group>
                    <b-form-input v-model="filterBy.firstname" type="text" class="form-control"
                      placeholder="Entrer le prénom de l'utilisateur. Exemple: Luc" />
                    <b-input-group-append is-text @click="() => {
                      filterBy.firstname = null;
                    }
                      ">
                      <feather-icon v-b-tooltip.hover.bottom="'Vider le champs!'" icon="XCircleIcon"
                        class="cursor-pointer" />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>
            </b-col>
            <b-col lg="6">
              <!-- email -->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group class="col pr-0 pl-0" :label="$t('user_manage.filter_label_email')">
                  <b-input-group>
                    <b-form-input v-model="filterBy.email" type="text" class="form-control"
                      placeholder="Entrer l'email de l'utilisateur. Exemple: agent@gmail.com" />
                    <b-input-group-append is-text @click="() => {
                      filterBy.email = null;
                    }
                      ">
                      <feather-icon v-b-tooltip.hover.bottom="'Vider le champs!'" icon="XCircleIcon"
                        class="cursor-pointer" />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>
            </b-col>
            <b-col lg="12">
              <span class="d-flex justify-content-between">
                <!-- export button -->
                <b-button
                  class="float-right"
                  variant="primary"
                  @click="exportListUsers()"
                >
                  <feather-icon
                    icon="fileIcon"
                    class="mr-25"
                    v-if="!isLoadingExportData"
                  />
                  <b-spinner v-else small class="mr-25" />
                  <span>{{ $t("button.export") }} (xlsx)</span>
                </b-button>
                <!-- button -->
                <div class="d-flex flex-row-reverse nopadding mt-1">
                  <b-button class="float-right" variant="warning" @click="getListUsers()">
                    <feather-icon icon="SearchIcon" class="mr-25"  />
                    <span>{{ $t("button.search") }}</span>
                  </b-button>
                </div>
              </span>
            </b-col>
          </b-row>
        </app-collapse-item>
      </app-collapse>
    </b-card>

    <b-card>
      <!-- table -->
      <b-table responsive :items="tableDataStructure" :fields="tableColumns" show-empty empty-text="Aucun resultats"
        :busy="isLoadingTableDataStructure" hover>
        <!-- Custom Header -->
        <template #head()="{ label, field: { key, sortable } }">
          {{ $t(label) }}
        </template>
        <!-- table busy -->
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #cell(id)="data">
          <strong>{{
            (paginationData.per_page * (paginationData.currentPage - 1) || 0) +
            data.index +
            1
          }}</strong>
        </template>
        <template #cell(roles)="data">
          <span v-for="(role, roleK) in data.item.roles" :key="roleK">
            <b-badge variant="light-primary"> {{ role.title }} </b-badge>
          </span>
        </template>
        <template #cell(actions)="data">
          <div class="d-flex flex-row">
            <b-button v-if="true" variant="primary" :to="{
              name: 'users-details',
              params: { userId: data.item.id },
            }">
              <feather-icon icon="EyeIcon" />
              <span class="text-nowrap"> {{ $t("button.see") }}</span>
            </b-button>
          </div>
        </template>
      </b-table>
      <div>
        <PaginationComponent :table_data="tableDataStructure" :per_page="paginationData.per_page"
          :total-rows="paginationData.total" @change="onPaginationChange" />
      </div>
    </b-card>

    <!-- add new administration agent -->
    <b-modal id="modal-add-user-administration" ref="modal-add-user-administration" cancel-variant="outline-secondary"
      centered :title="$t('user_manage.add_admin_card_title')" hide-footer
      no-close-on-backdrop
      >
      <validation-observer ref="formRules">
        <b-form>
          <b-form-group>
            <template v-slot:label>
              <span>{{
                $t("user_manage.add_admin_card_label_lastname") }}
                <RequiredStar />
              </span>
            </template>
            <validation-provider v-slot="{ errors }" rules="required">
              <!-- <label for="lastname"
                >{{ $t("user_manage.add_admin_card_label_lastname") }}:</label
              > -->
              <b-form-input id="lastname" v-model="newAgent.lastname" type="text" :placeholder="$t('user_manage.add_admin_card_label_lastname_placeholder')
                " />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <template v-slot:label>
              <span>{{
                $t("user_manage.add_admin_card_label_firstname") }}
                <RequiredStar />
              </span>
            </template>
            <validation-provider v-slot="{ errors }" rules="required">
              <!-- <label for="firstname"
                >{{ $t("user_manage.add_admin_card_label_firstname") }}:</label
              > -->
              <b-form-input id="firstname" v-model="newAgent.firstname" type="text" :placeholder="$t('user_manage.add_admin_card_label_firstname_placeholder')
                " />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <template v-slot:label>
              <span>{{
                $t("user_manage.add_admin_card_label_email") }}
                <RequiredStar />
              </span>
            </template>
            <validation-provider v-slot="{ errors }" rules="required">
              <!-- <label for="email"
                >{{ $t("user_manage.add_admin_card_label_email") }}:</label
              > -->
              <b-form-input id="email" v-model="newAgent.email" type="email" :placeholder="$t('user_manage.add_admin_card_label_email_placeholder')
                " />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <template v-slot:label>
              <span>{{
                $t("user_manage.add_admin_card_label_role") }}
                <RequiredStar />
              </span>
            </template>
            <validation-provider v-slot="{ errors }" rules="required">
              <!-- <label for="role">
                {{ $t("user_manage.add_admin_card_label_role") }}:
              </label> -->
              <v-select id="role" v-model="newAgent.roles" multiple :options="opiton_role" label="title"
                :reduce="(option) => option.value"
                placeholder="Choisissez un rôle. Exemple: Utilisateur Interne (Agent MTCA)" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <hr />

      <div class="d-flex flex-row-reverse">
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="registerUser()">
          <b-spinner v-if="loadingAddAdmin" variant="primary my-auto" small />
          {{ $t("user_manage.add_admin_card_button") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BPagination,
  BButton,
  BTable,
  BSpinner,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormSelect,
  BAvatar,
  BCardText,
  BCard,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BFormCheckbox,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  VBTooltip,
  BCardTitle,
} from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
import ListUtilisateursComponent from "@/views/apps/utilisateurs/administration/component/ListUtilisateursComponent.vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import DateRangeWidget from "@/components/DateRangeWidget.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";

// store modules and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import usersStoreModule from "@/store/users";
import agentGouvenementStoreModule from "@/store/agent-gouvenement";
import rolesStoreModule from "@/store/roles";
import RequiredStar from "@/components/RequiredStar.vue";


import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  name: "UsersList",
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "users", module: usersStoreModule },
      { path: "agentGouvenement", module: agentGouvenementStoreModule },
      { path: "roles", module: rolesStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },

  components: {
    BCard,
    BCardText,
    BCard,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormSelect,
    vSelect,
    BPagination,
    BAvatar,
    BButton,
    BTable,
    BSpinner,
    BRow,
    BCol,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    VBTooltip,
    ListUtilisateursComponent,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    PaginationComponent,
    DateRangeWidget,
    AppCollapse,
    AppCollapseItem,
    RequiredStar
  },

  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },

  data() {
    return {
      tableData: [],
      filters: { options: {} },
      newAgent: {
        firstname: null,
        lastname: null,
        email: null,
        roles: null,
      },
      opiton_role: [
        { title: "Administrateur", value: "A" },
        { title: "Utilisateur Interne (Agent MTCA)", value: "AUAT" },
        { title: "Administrateur MTCA", value: "UAT" },
        { title: "Agent Organe Technique", value: "TA" },
        { title: "Administrateur Organe Technique", value: "TAD" },
        /*{ title: "Utilisateur Externe de l'Administration", value: "UEA" },
        { title: "Utilisateur Externe Hébergement", value: "UEH" },
        { title: "Utilisateur Externe Agence de Voyages", value: "UEAV" },
        { title: "Utilisateur Externe Guide de Tourisme", value: "UEGT" }, 
        {
          title:
            "Utilisateur Externe Etablissement de Restauration et Assimilé",
          value: "UER",
        },*/
        {
          title: "Auditeur",
          value: "AUD",
        },
        { title: "Ministre", value: "MIN" },
      ],
      loading: false,
      loadingAddAdmin: false,

      form: {
        role: "",
      },
      filters: { options: {} },
      tableData: null,
      tableDataEtablissement: [],
      tableDataRole: [],
      filteredTableData: [],
      tableDataStructure: [],
      paginationData: {
        currentPage: 1,
        total: 0,
        per_page: 20,
        metaData: { from: 0, to: 0 },
      },
      tableColumns: [
        {
          key: "id",
          label: "#",
          sortable: true,
          // stickyColumn: true,
          thStyle: { width: "5%" },
        },
        {
          key: "lastname",
          label: "table_columns.lastname",
          sortable: true,
          // stickyColumn: true,
        },
        {
          key: "firstname",
          label: "table_columns.firstname",
          sortable: true,
          // stickyColumn: true,
        },
        {
          key: "email",
          label: "table_columns.email",
          sortable: true,
          // stickyColumn: true,
        },
        {
          key: "roles",
          label: "table_columns.role",
          sortable: true,
          // stickyColumn: true,
          thStyle: { width: "20%" },
        },
        {
          key: "structure",
          label: "table_columns.structure",
          sortable: true,
          // stickyColumn: true,
          thStyle: { width: "30%" },
        },
        {
          key: "actions",
          thStyle: { width: "15%" },
          // stickyColumn: true,
        },
      ],
      isLoadingTableData: false,
      isLoadingTableDataStructure: false,

      isLoadingExportData: false,

      filterBy: {
        periode: null,
        email: null,
        firstname: null,
        lastname: null,
        etablissement: null,
        role: null,
      },
    };
  },
  computed: {
    ...mapGetters("params", {
      typesRoles: "getter_typesRoles",
    }),
    totalRows() {
      return (this.tableData && this.tableData.length) || 0;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.getListUsers();
        this.getListEtablissement();
        this.getListRole();
      },
    },
    per_page: {
      immediate: true,
      handler(val, old) {
        if (this.tableData && this.tableData[val - 1]) {
          this.filteredTableData = this.customCloneDeep(
            this.tableData.slice(0, val - 1)
          );
        }
      },
    },
    tableData: {
      immediate: true,
      handler(val, old) {
        if (this.tableData) {
          this.filteredTableData = this.customCloneDeep(this.tableData);
          this.loadTableDataStructure()
        }
      },
    },
    "paginationData.currentPage": {
      handler(val, old) {
        this.getListUsers();
      },
    },
    "paginationData.per_page": {
      handler(val, old) {
        this.getListUsers();
      },
    },
  },
  beforeDestroy() {
    // unregisterStoreModule(this.requiredStoreModules);
  },
  async mounted() { },
  methods: {
    ...mapActions("users", {
      action_searchUsers: "searchUsers",
      action_exportUsers: "exportUsers",
      action_getUser: "getUser",
      action_addUser: "addUser",
    }),
    ...mapActions("params", { action_fetchTypesRoles: "fetchTypesRoles" }),
    ...mapActions("etablissements", {
      fetchEtablissements: "fetchEtablissements",
      action_getListTypesEtablissement: "getListTypesEtablissement",
      action_searchEtablissement: "searchEtablissement",
    }),
    ...mapActions("roles", { action_fetchRoles: "fetchRoles" }),
    ...mapActions("agentGouvenement", {
      createAgentGouvenement: "createAgentGouvenement",
      findAgentGouvenement: "findAgentGouvenement",
    }),
    // ------------------------------------------------------------
    async getListUsers() {
      this.isLoadingTableData = true;
      const params = {
        ...this.filterBy,
        page: this.paginationData.currentPage - 1,
        per_page: this.paginationData.per_page,
      };
      // console.log("params::: ", params);
      this.action_searchUsers(params)
        .then(async (response) => {
           console.log("response::: ⭕️", response);
          if (response.status === 200) {
            this.tableData = response.data.data || [];
            this.tableData = this.tableData.filter(user => user.is_active)
            
            this.paginationData.total = response.data.total || 0;
            this.isLoadingTableData = false;
            // -------------------------------------------------------
            this.paginationData.total = response.data.meta.total || 0;
            if (this.paginationData.metaData.from === 0) {
              this.paginationData.metaData.from += 1;
            } else {
              this.paginationData.metaData.from =
                this.paginationData.per_page * this.paginationData.currentPage -
                this.paginationData.per_page;
            }
            this.paginationData.metaData.to =
              this.paginationData.per_page * this.paginationData.currentPage -
              this.paginationData.per_page +
              response.data.meta.total;
              
            // -------------------------------------------------------
          }
        })
        .catch((err) => {
          this.isLoadingTableData = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    async exportListUsers() {
      this.isLoadingExportData = true;
      const params = {
        ...this.filterBy,
        page: this.paginationData.currentPage - 1,
        per_page: this.paginationData.per_page,
        export: true
      };
      console.log("params: ⭕️", params);
      this.action_exportUsers(params)
        .then((response) => {
          this.isLoadingExportData = false;
          console.log("response action_exportUsers::: ⭕️", response.data);
          const blob = new Blob([response.data], { type: 'text/xlsx' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Utilisateurs.xlsx');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Exportation réussie",
              icon: "CheckIcon",
              variatnt: "success",
            },
          });
        })
        .catch((err) => {
          console.log('err::: ', err);
          this.isLoadingExportData = false;
        });
    },
    async getListEtablissement() {

      const reqOptions = {
        // per_page: 100,
        // page: 1,
      };
      this.action_searchEtablissement(reqOptions)
        .then(async (response) => {
          if (response.status === 200) {
            this.tableDataEtablissement = response.data.data || [];
          }
        })
        .catch((err) => {
          console.log("err::: ", err);
        });
    },
    async getListRole() {
      this.isLoadingTableDataStructure = true
      this.action_fetchRoles()
        .then(async (response) => {
          if (response.status === 200) {
            this.loadTableDataStructure()
            this.tableDataRole = response.data.data || [];
            this.isLoadingTableDataStructure = false
          }
        })
        .catch((err) => {
          console.log("err::: ", err);
        });
    },
    onPaginationChange(val) {
      this.paginationData.currentPage = val.currentPage;
      this.paginationData.per_page = val.per_page;
    },
    registerUser() {
      this.$refs.formRules.validate().then(async (success) => {
        if (success) {
          if (this.loadingAddAdmin) return;

          this.loadingAddAdmin = true;

          // Execute reCAPTCHA with action "submit".
          const token = await this.$recaptcha("submit");
          if (!token) {
            this.loadingAddAdmin = false;
            return;
          }
          this.newAgent.token = token;
          console.log('this.newAgent::: ', this.newAgent);

          // return;
          this.action_addUser(this.newAgent)
            .then(async (res) => {
              console.log("res::: ", res);
              if (res.status === 201) {
                this.loadingAddAdmin = false;
                this.$refs["modal-add-user-administration"].hide();
                this.newAgent = {
                  firstname: null,
                  lastname: null,
                  email: null,
                  role: null,
                };
                this.getListUsers();
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Opération réussie",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
              }
            })
            .catch((err) => {
              this.loadingAddAdmin = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: err.message || "Une erreur est survenue",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
     async loadTableDataStructure(){
    
      if(this.tableData)
            {
              this.tableDataStructure = this.tableData.map(
        element => ({
        ...element,
        structure: '',
        }),
        )
        this.tableDataStructure.forEach(element => {
            switch (element.roles[0].title) {
    case "Utilisateur Externe Hébergement":
    case "Utilisateur Externe Etablissement de Restauration et Assimilé":
    case "Utilisateur Externe Agence de Voyages":
      element.structure =element.profile && element.profile.nom_etablissement;
      break;
    case "Agent Organe Technique":
    case "Administrateur Organe Technique":
      element.structure = "Organe Technique";
      break;
    case "Agent MTCA":
    case "Administrateur MTCA":
      element.structure = "MTCA";
      break;
    case "Auditeur":
      element.structure = "Auditeur externe";
      break;
    case "Administrateur":
      element.structure = "Administrateur";
      break;
    case "Utilisateur Externe Guide de Tourisme":
      element.structure = "Statut indépendant";
      break;
    case "Utilisateur Externe de l'Administration":
      element.structure = "Utilisateur Externe de l'Administration";
      break;
    default:
      console.log("Role inconnu", element.roles[0].title);
          element.structure = element.roles[0].title;
          }
        });
      
            }
      
    }
  },
};
</script>

<style lang="scss" scoped>
.link-wrapper {
  display: flex;
  height: 40px;
  align-items: center;
  gap: 10px;

  a {
    text-transform: uppercase;
  }
}

.footer,
.footer .per-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-select {
  width: 100%;
}

.div .card-header .collapse {
  padding: 0 !important;
}
</style>
